<template>
  <div>
    <div class="box">
      <el-page-header
        @back="$router.go(-1)"
        :content="$t('tSubjectDetail')"
      ></el-page-header>
    </div>
    <el-card class="content mt20 bbb">
      <el-form
        label-position="left"
        :model="ruleForm"
        ref="ruleForm"
        label-width="100px"
        class="demo-ruleForm"
      >
        <el-form-item :label="$t('subject.create_time')" prop="create_time">
          <div>{{ ruleForm.create_time }}</div>
        </el-form-item>
        <el-form-item :label="$t('subject.title')" prop="title">
          <div>{{ ruleForm.title }}</div>
        </el-form-item>
        <el-form-item :label="$t('subject.position')" prop="position">
          <div>{{ ruleForm.position }}</div>
        </el-form-item>
        <el-form-item :label="$t('subject.chapter')" prop="chapter">
          <div>{{ ruleForm.chapter }}</div>
        </el-form-item>
        <el-form-item :label="$t('subject.examination')" prop="examination">
          <div>{{ ruleForm.examination }}</div>
        </el-form-item>
        <el-form-item :label="$t('subject.status')" prop="status">
          <div>{{ status(ruleForm.status) }}</div>
        </el-form-item>
        <el-form-item :label="$t('subject.is_hot')" prop="is_hot">
          <div>{{ is_hot(ruleForm.is_hot) }}</div>
        </el-form-item>
        <el-form-item :label="$t('subject.price')" prop="price">
          <div>{{ruleForm.price}}</div>
        </el-form-item>
        <el-form-item :label="$t('subject.time')" prop="time">
          <div>{{ ruleForm.time }}</div>
        </el-form-item>
        <el-form-item :label="$t('subject.order')" prop="order">
          <div>{{ ruleForm.order }}</div>
        </el-form-item>
        <el-form-item :label="$t('subject.cover')" prop="cover">
          <el-image
            style="width: 100px; height: 100px"
            :src="ruleForm.cover"
            :preview-src-list="[ruleForm.cover]"
          >
          </el-image>
        </el-form-item>
        <el-form-item :label="$t('subject.content_desc')" prop="content_desc">
          <div v-html="ruleForm.content_desc"></div>
        </el-form-item>
      </el-form>
    </el-card>
    <div class="fs30 fwB mt20">{{ $t("subject.content") }}</div>
    <el-card class="mt20" v-for="(item, index) in ruleForm.content" :key="index">
        <el-form
        label-position="left"
        :model="ruleForm"
        ref="ruleForm"
        label-width="100px"
        class="demo-ruleForm">
        <el-form-item :label="$t('subject.con_name')" prop="name">
            <div>{{ item.name }}</div>
          </el-form-item>
          <el-form-item :label="$t('subject.con_title')" prop="title">
            <div>{{ item.title }}</div>
          </el-form-item>
          <el-form-item :label="$t('subject.con_desc')" prop="desc">
            <div>{{ item.desc }}</div>
          </el-form-item>
          <el-form-item :label="$t('subject.con_service')" prop="service">
            <div>{{ item.service }}</div>
          </el-form-item>
          <el-form-item :label="$t('subject.con_price')" prop="price">
            <div>{{ item.price }}</div>
          </el-form-item>
          <el-form-item :label="$t('subject.con_days')" prop="days">
            <div>{{ item.days }}</div>
          </el-form-item>
        </el-form>
    </el-card>
  </div>
</template>
<script>
import { subjectDetail } from "@/api/index";
export default {
  name: "subjectDetail",
  data() {
    return {
      ruleForm: {
        id: "",
        order: 0,
        position: "",
        position_id: 0,
        status: 0,
        price:0,
        time:"",
        title: "",
        update_time: "",
        examination: 0,
        create_time: "",
        cover: "",
        chapter: 0,
        content: [],
        content_desc:""
      },
    };
  },
  computed: {
    id() {
      return Number.isNaN(Number(this.$route.query.id))
        ? undefined
        : Number(this.$route.query.id);
    },
  },
  methods: {
    status(status) {
      switch (status) {
        case 0:
          return this.$t("subject.status0");
        case 1:
          return this.$t("subject.status1");
      }
    },
    is_hot(is_hot) {
      switch (is_hot) {
        case 0:
          return this.$t("subject.is_hot0");
        case 1:
          return this.$t("subject.is_hot1");
      }
    },
    async get_info() {
      const information = await subjectDetail(this.id);
      this.ruleForm = { ...information };
    },
  },
  async created() {
    if (this.id === undefined) return;
    this.get_info();
  },
};
</script>
<style land="scss" scoped>
.el-date-editor {
  margin-right: 10px;
}
.bbb {
  position: relative;
  padding-bottom: 50px;
}
.bbb .btn {
  position: absolute;
  bottom: 10px;
  right: 20px;
}
</style>
